import { Checkbox, Col, Form, FormInstance, Input, message, Row, Select, Table, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { MasterTypes } from "../../../Constant/Enums";
import { useQuery } from "@tanstack/react-query";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
interface IBatchType {
    data: any;
    form: FormInstance<any>;
    batchDaysData:any;
    batchDays:any;
    setBatchDays:any
  }
const AddEditBatch = ({ data, form, batchDaysData ,batchDays,setBatchDays}: IBatchType) => {
    const [currentSchema, setCurrentSchema]: any = useState();
    
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };

  const daysOfWeek = [
    { key: "sun", day: 1 },
    { key: "mon", day: 2 },
    { key: "tue", day: 3 },
    { key: "wed", day: 4 },
    { key: "thu", day: 5 },
    { key: "fri", day: 6 },
    { key: "sat", day: 7 },
  ];
  
  useEffect(()=>{
    if(!!data){
        const batchDays = daysOfWeek.reduce((acc:any, { key, day }) => {
          acc[key] = data?.batchDays?.some((d: any) => d?.day === day && d?.isChecked);
          return acc;
        }, {});
        
        setBatchDays(batchDays);
    const batchDetail= { masterType : MasterTypes.Batch,
        ...(data?.id && { id: data?.id }),
        ...data,
        inTime:dayjs(data?.inTime, "hh:mm A")||null,
        outTime:dayjs(data?.outTime, "hh:mm A")||null,
        batchDays:batchDays,
        // department: {
        //   label: data?.department?.name,
        //   value: data?.department?.id,
        // },
        // course: {
        //   label: data?.course?.name,
        //   value: data?.course?.id,
        // },
        // schema: {
        //   label: data?.schema?.name,
        //   value: data?.schema?.id,
        // }
      };
        form.setFieldsValue(batchDetail)
    }

  },[data,batchDays])
  
  useEffect(() => {
    if (currentSchema !== undefined && currentSchema?.length !== 0) {
      form.setFieldsValue({
        schema: currentSchema[0],
      });
    }
  }, [currentSchema]); 

  const columnsNonBatch: ColumnsType<IBatchType> = [
    {
      title: "Mon",
      dataIndex: "mon",
      key: "1",

      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.mon}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, mon: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Tue",
      dataIndex: "tue",
      key: "2",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.tue}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, tue: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Wed",
      dataIndex: "wed",
      key: "3",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.wed}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, wed: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Thu",
      dataIndex: "thu",
      key: "4",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.thu}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, thu: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Fri",
      dataIndex: "fri",
      key: "5",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.fri}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, fri: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sat",
      dataIndex: "sat",
      key: "6",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.sat}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, sat: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sun",
      dataIndex: "sun",
      key: "7",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.sun}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, sun: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
  ];

  const { data: masterDetail, isLoading:masterLoading, refetch } = useQuery(
    {
        queryKey: ['MasterDetail',data.department.value,data.course.value,data.schema.value],            
        queryFn: (async () => {
          let res = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.GET_COURSE_DETAILS,
            {},
            {
                departmentId:data?.department?.value,
              courseId:data.course.value,
              schemaId:data.schema.value
            })
            setCurrentSchema(res?.result?.schemas?.map((itm: any) => ({
              value: itm?.id,
              label: itm?.name,
            })))
            // sem);
              return {
                departments: res?.result?.departments?.map((itm: any) => ({
                  value: itm?.id,
                  label: itm?.name,
                  MasterTypes:itm.masterType,
                  name: itm?.name,
                })),
                courses: res?.result?.courses?.map((itm: any) => ({
                  value: itm?.id,
                  label: itm?.name,
                  MasterTypes:itm.masterType,
                  name: itm?.name,
                })),
                schemas: res?.result?.schemas?.map((itm: any) => ({

                  value: itm?.id,
                  label: itm?.name,
                  MasterTypes:itm.masterType,
                  name: itm?.name,
                })),
                // semesters: res?.result?.semesters?.map((itm: any) => ({
                //   value: itm?.id,
                //   label: itm?.name,
                //   MasterTypes:itm.masterType,
                //   name: itm?.name,
                // })),
                // subjects: res?.result?.subjects?.map((itm: any) => ({
                //   value: itm?.id,
                //   label: itm?.name,
                //   MasterTypes:itm.masterType,
                //   name: itm?.name,
                // })),
              }
              
              

            // }
            // return transformedItems;
      })
    },

)

  const [batchOpt, setBatchOpt] = React.useState<any[]>([]);

  return (
    <>
      <Form
        form={form}
        name="batch-form"
        className="DrawerPadding"
        colon={false}
        autoComplete="off"
        style={{ padding: 16 }}
        {...formItemLayout}
        labelAlign="left"
        requiredMark={false}
        // initialValues={{ schema: currentSchema }}
      >
        <Form.Item
          label="Department"
          name="department"
          rules={[{ required: true, message: "Please select Department!" }]}
        >
          <Select
            placeholder="Choose Department"
            optionFilterProp="children"
            labelInValue={true}
            // labelInValue
            allowClear
            popupMatchSelectWidth={true}
            showSearch
            // filterOption={(input, option) =>
            //   (option?.label?.toString() ?? "")
            //     .toLowerCase()
            //     .includes(input.toLowerCase())
            // }
            options={masterDetail?.departments}
            // options={masterDetail?.departments}
            // options={masterDetail?.departments?.map((item:any) => ({
            //   value: item.value,
            //   label: item.name,
            // }))}
            onSelect={(e: any) => {
            //   setDepartmentId(e.value);
              form.resetFields(["course", "schema"]);
            }}
            // onClear={() => {
            //   form.resetFields();
            //   setDepartmentId("string");
            //   setCourseId("string");
            // }}
          />
        </Form.Item>
        <Form.Item
          label="Course"
          name="course"
          rules={[{ required: true, message: "Please select Course!" }]}
        >
          <Select
            placeholder="Choose Course"
            optionFilterProp="children"
            labelInValue={true}
            allowClear
            popupMatchSelectWidth={true}
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={masterDetail?.courses}
            // options={masterDetail?.courses?.map((item:any) => ({
            //   value: item.value,
            //   label: item.name,
            // }))}
            onSelect={(e: any) => {
            //   setCourseId(e.value);
              // setSessionId(e.value);
            }}
            onClear={() => {
            //   setCourseId("string");
            }}
          />
        </Form.Item>

        <Form.Item
          label="Schema"
          name="schema"
          rules={[{ required: true, message: "Please select Semester!" }]}
        >
          <Select
            placeholder="Choose Schema"
            optionFilterProp="children"
            labelInValue={true}
            // disabled={true}
            allowClear
            popupMatchSelectWidth={true}
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={masterDetail?.schemas}

            // defaultValue={currentSchema}
          />
        </Form.Item>
        <Form.Item
          label="Batch Name"
          name="name"
          rules={[{ required: true, message: "Please enter Batch Name!" }]}
        >
          <Input
            placeholder="Enter Batch Name"
            size="middle"
            onInput={(e: any) =>
              (e.target.value =
                e.target.value.length > 1
                  ? e.target.value
                  : e.target.value.toUpperCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Batch Code"
          name="code"
          rules={[{ required: true, message: "Please enter Batch Code!" }]}
        >
          <Input
            placeholder="Enter Batch Code"
            size="middle"
            onInput={(e: any) =>
              (e.target.value =
                e.target.value.length > 1
                  ? e.target.value
                  : e.target.value.toUpperCase())
            }
          />
        </Form.Item>

        <Form.Item
          label="In Time"
          name="inTime"
          rules={[{ required: true, message: "Please select time!" }]}
        >
          <TimePicker format="h:mm:A" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Out Time"
          name="outTime"
          rules={[{ required: true, message: "Please select time!" }]}
        >
          <TimePicker format="h:mm:A" style={{ width: "100%" }} onChange={()=> console.log("form",form.getFieldsValue())
          } />
        </Form.Item>
        <Row className="mb-3">
          <Col className="fw500">Batch Days</Col>
          <Col span={24} className="mt-1">
            <Table
              bordered={true}
              columns={columnsNonBatch}
              pagination={{ hideOnSinglePage: true }}
              dataSource={batchDaysData}
              size={"small"}
              scroll={{ x: 400 }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddEditBatch;
